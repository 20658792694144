@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Coiny&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

@font-face {
  font-family: "Besty";
  src: local("'Besty'"), url(Besty.ttf);
}
@font-face {
  font-family: "Kiddos";
  src: local("'Kiddos-Demo'"), url(Kiddos-Demo.ttf);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  color: black;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  background: "var(--primary)";
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

h2{
  font-family: 'Besty';
  font-size: 3em;
  font-weight: 900;
}
h3{
  font-family: 'Besty';
}
p{
  font-family: 'Kiddos';
  font-size: 1.5em;
}


body {
  margin: 0;
  font-family: "Montserrat", "Besty", "Source Sans Pro", "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: "Montserrat", "source-code-pro", Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}

div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.loading{
  padding: 100px;
  height: 100vh;
  background-color: var(--light-pink);
  text-align: center;
}

/* HEADER */
.header{
  position: absolute;
  top: 30px;
  z-index: 3;
  
  display: flex;
  justify-content: space-between;
  width: 70vw;
  height: 90px;
  padding: 0 10px;
  background: white;
  color: black;
  border: 2px solid black;
  border-radius: 20px;
  box-shadow: 1px 1px;

  font-family: 'Coiny';
  font-size: 1.2em;
}

.headerItems{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    transition: all ease-in-out 100ms;
    z-index: 2;
}

.brand_logo_a{
  width: 33%;
}
.brand_logo{
  margin: 0;
  height: 160px;
  width: auto;

  transition: all ease-in-out 200ms;
}
.brand_logo:hover{
  transform: scale(1.1);
}

.header_p{
  width: 22%;
  display: flex;
  justify-content: end;
}

/* NAVBAR */
.menus{
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 100ms;
  font-size: 1.1em;
}

.menu-items, .submenu-items{
  margin: 0 3px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 100ms;

  height: 30px;
  padding: 8px 10px;
}
.menu-items a, .submenu-items a{
  text-decoration: none;
  font-size: 0.8em;
}
.menu-items button, .submenu-items button {
  color: white;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  font-size: 0.9em;
  font-weight: 600;
}

.menu-items:hover, .submenu-items:hover{
  background-color: gray;
}

.dropdown{
  display: none;
  position: absolute;
  left: auto;
  top: 30px;
  box-shadow: 0 10px 15px -3px rgba(253, 253, 253, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  border-radius: 0.5rem;
}

.dropdown.show{
  display: block;
  background-color: black;
}

@media (max-width: 950px) {
  .header{
    width: 80%;
    
  }
  .menus{
    flex-direction: column;
  }
  
  .menu{
    background: var(--primary);
    width: 100%;
    flex-direction: column;
    position: absolute;
    padding-top: 8px;
    align-items: center;
    left: 0;
    font-size: 2.2em;
  }
  .menu-items{
    font-size: 0.8em;
    margin-bottom: 4px;
  }
  .submenu-items{
    height: auto;
    width: auto;
  }
  .menu-items a{
    font-size: 0.8em;
  }
}

/* Home */
.hero_wrapper, .heroB_wrapper{
  width: 100%;
  height: auto;
}
.heroB_wrapper{
  height: auto;
  padding: 80px 0;
  background: var(--light-pink);

}

.hero, .about_img_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  overflow: hidden;

  z-index: 2;
}
.hero_container{
  margin: 0;
  width: auto;
  padding: 10px;
  border-radius: 10px;
}
.main_h2{
  height: auto;
  font-family: "Besty";
  /* font-size: 2.8em; */
  font-weight: 900;
  color: var(--pink);
}

.hero h2, .hero p{
  z-index: 2;
}

.hero img{
  width: 100%;
  height: 100%;
}
.about_img_container{
  width: 100%;
}

.hero_main_img, .about_main_img{
  z-index: 2;
  width: 100%;
}
.hero_main_img2{
  display: none;
}

.boobies_bg{
  position: absolute;
  top: -140px;
  left: 0;

  /* width: 100%; */
  /* height: 80vh; */
}
.home_first_row{
  background: var(--light-pink);
  
  padding: 100px 0;
  margin-top: -10%;

  overflow: hidden;

  z-index: 0;
}
.hero2_container{
  background: var(--light-pink);

  padding: 90px 0;
  margin-top: -10%;
}
.home_first_row_container{
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 90px;
}
.hero2_content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.hero_container{
  text-align: center;
}
.multicolor_h2{
  color: transparent;
  background: linear-gradient(89.03deg, #FF4A4A 0.75%, #EC7D2C 11.62%, #FFDE32 20.38%, #59F155 30.26%, #3761F5 46.26%, #6F31F1 63.44%, #FD4CF6 96.92%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px rgb(255, 255, 255);
  letter-spacing: -0.05em;
  font-weight: 400;
}

.home_first_row h2{
  font-size: 2.9em;
  font-weight: 700;
  margin-bottom: 30px;
}
.home_first_row p, .hero p{
  line-height: 2;
}
.span{
  color: var(--yellow)
}

.first_row_left{
  width: 50%;
  margin-top: 20px;
  margin-right: 5vw;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hero2_inner{
  height: max-content;
  width: 70%;
  /* margin-top: 80px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
}
.first_row_left h2{
  width: 100%;
}
.first_row_right{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5vw;
  /* background-color: rgba(48, 64, 145, 0.753); */
}
.first_row_right img{
  width: 30vw;
}

.call_to_action{
  margin-top: 30px;
  padding: 8px;
  color: black;

  text-decoration: none;
  background-color: white;
  border-radius: 5px;

  font-family: 'Coiny';
  letter-spacing: -0.05em;
  font-weight: 400;
}

.pink_a{
  margin: 20px;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: var(--pink);

  font-family: "Kiddos";
  box-shadow: 0 3px var(--light-pink);
}
.pink_button{
  margin: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  background-color: var(--pink);

  font-family: "Kiddos";
  font-size: 1.8em;
  box-shadow: 0 3px var(--light-pink);
}

@media only screen and (max-width: 750px){
  .hero h2{
    font-size: 1.8em;
  }

  .first_row_right img{
    width: 50vw;
  }
}

/* PHASE */
.phase_item{
  width: 55%;
}

.h1{
  font-size: 2em;
}

.description{
  padding: 8px;
  border-radius: 10px;
  background-color: var(--secondary);
  margin-bottom: 10px;
  transition: all ease-in-out 200ms;
}

.description:hover{
  transform: scale(1.3);
}

/* ABOUT */
.about_wrapper, .about_wrapperB{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.about_wrapper{
  margin-top: -7%;
}
.about_wrapperB{
  margin-top: -10%;
  background: #9FD5F0;
}
.pink_div{
  height: 300px;
  width: 100vw;
  background-color: var(--light-pink);

  position: absolute;
  bottom: -300px;

}

.about_container, .about_containerB{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  padding: 35px 0 80px 0;
  margin-top: -15%;
  padding-top: 12%;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  position: relative;

  z-index: 1;
}
.about_container{
  justify-content: center;

  background-image: url('./img/what_is_that_vision.png');
  background-position: bottom;
}
.about_containerB{
  background-image: url('./img/our_narrative.png');
  background-position: bottom;
  min-height: 50vw;
}

.about_container2{
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  /* height: 90vw; */
  padding: 80px 0 80px 0;
  margin-top: -12vw;
  background-image: url('./img/planet_bg.png');
  background-position: bottom;
  background-size: 100%;
  background-repeat: no-repeat;

  background-color: var(--light-pink);
}
.VideoTagHome{
  width: 80%;
  height: auto;
  z-index: 1;
}
.blue_div_bg_cover{
  height: 600px;
  width: 100vw;
  position: absolute;
  top: 0;
  z-index:0;
  background-color: var(--blue);
}
.about_text2{
  position: relative;

  margin-top: 30px;
  margin-bottom: 32vw;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  text-align: center;
}

.what_is_img{
  position: relative;

  width: 100%;
  height: 100%;
}
.celeste_img{
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;

  z-index: -1;
}
.mushroom{
  display: none;
  position: absolute;
  bottom: -2%;
  left: 0;
  transform: rotate(3deg);

  width: 20vw;
  z-index: 1;
}
.about_title{
  margin-bottom: 20px;
}
.about_title2{
  align-self:flex-start;
}
.about_title{
  font-weight: 600;
  font-size: 2em;
}
.d2e_wrapper p{
  width: 100%;
}
.about1{
  width: 680px;
  font-size: 0.7em;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 200;
}
.about2{
  width: 80%;
  font-size: 1.2em;
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 1.9;
  text-align: center;
  color: white;
}
.about2 li{
  color: white;
}
.about_img{
  width: 70vw;
}
.about_img img{
  width: 100%;
}
.about_text{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  text-align: center;

  margin-top: 100px;

  position: relative;
  z-index: 1;
}
.about_textB{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  text-align: center;

  position: relative;
  z-index: 1;
}


.about_text h2, .about_textB h2{
  color: var(--blue);
  text-shadow: 0px 3px 0px #FFFFFF;
}
.about_textB h2{
  margin: 75px 0 75px 0;
}

/* ex Floating */
.vision_container{
  display: flex;
  justify-content: center;
  align-items: center;


}
.vision_element{
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 40%;
}
.floating_box{
  padding: 15px;
  width: 70%;

  background-color:var(--pink);
  border-radius: 7px;

  position: relative;
  top: 30px;
  z-index: 1;
}
.fl_bl{
  transform: rotate(-3deg);
}
.fl_br{
  transform: rotate(3deg);
}
.bottom_box{
  display: flex;
  align-items: flex-end;
  padding: 30px 25px;
  width: 85%;
  height: 190px;

  background-color: var(--light-pink);;
  border-radius: 7px;

  text-align: justify;

  /* just for zindex purposes */
  position: relative;
}
.about_bottom_p{
  margin: 60px 0;
  width: 60%;

  position: relative;
}

.blue_img{
  width: 100vw;
  position: absolute;

  background-image: url('./img/planet_bg.png');

  z-index: 0;
}
.blue_img_img{
  width: 100%;
  position: absolute;
  bottom: -16%;
  left: 0;
}
.about_container2_h2{
  color: white;
  position: relative;
  margin: 10% 0 30px 0;
}

.planet{
  display: none;
  position: absolute;
  z-index: 2;
  transform: rotate(3deg);

  bottom: 0;
  left: 0;

  width: 50vw;
}
.wood_sign{
  display: none;
  position: absolute;
  bottom: 3.5%;
  right: 0;

  width: 10vw;

  transform: rotate(3deg);
}

.read_more{
  position: relative;
  
  margin: 30px 0 40px 0;
}

/* PINK BOX */
.pinkbox_wrapper{
  width: 40%;
  padding: 40px 70px 40px 40px;
  margin: 60px 0;

  border: 2px solid #1D1D1D;
  box-shadow: 10px 10px 0px #1D1D1D;
  border-radius: 10px;
  background-color: var(--light-pink);;

  position: relative;

  font-family: Kiddos;
}

.pinkbox_title, .pinkbox_titleB{
  padding: 20px;
  background-color: var(--pink);
  color: white;

  border-radius: 10px;
}
.pinkbox_title{
  position: absolute;
  top:-25px;
  transform: rotate(-3deg);
}
.pinkbox_titleB{
  position: absolute;
  right: 25px;
  top:-25px;
  transform: rotate(3deg);
}
.pinkbox_text{
  line-height: 23px;
}

.pink_box_side_img{
  position: absolute;
  top: -85px;
  right: -120px;
}
.pink_box_side_img img{
  width: 50%;
}
.pink_box_side_imgB{
  position: absolute;
  top: -85px;
  left: -120px;
}
.pink_box_side_imgB img{
  width: 50%;
}

/* .pdf{
  width: 100%;
  height: 100%;
} */

@media only screen and (max-width: 1170px){
  .about_text2{
    width: 95%;
  }
  .about2{
    width: 95%;
  }

  .vision_container{
    justify-content: space-around;
  }
  .vision_element{
    width: 45%;
  }
  .bottom_box{
    width: auto;
    height: max-content;
    padding-top: 10%;
    font-size: 0.9em;
  }
}

/* FAQ */
.faq_wrapper{
  width: 100%;
  background-color: #9FD5F0;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 60px 0 130px 0;
}
.faq_container{
  display: flex;
  flex-direction: column;
  width: 75%;

  align-items: center;
  color: white;
}

.faq_container h2{
  margin-bottom: 30px;
}


.faq_wrapper .Collapsible {
  background-color: var(--blue);
  width: 80%;
  border-radius: 10px;
  border: 2px solid #1F1F1F;

  box-shadow: 5px 5px 0px #1F1F1F;
}

.Collapsible__contentOuter{
  display: flex;
  justify-content: center;
  align-items: center;
}
/* The content within the collaspable area */
.faq_wrapper .Collapsible__contentInner {
  width: 80%;
  font-size: 1.2em;
  font-weight: 400;
  /* border: 2px solid #1F1F1F; */
  /* box-shadow: 20px 20px 0px #1F1F1F; */
  border-top: 0;
}

.faq_wrapper p {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 20px;
    color: white;
}
.faq_container .Collapsible{
  margin-bottom: 30px;
}
.faq_wrapper .Collapsible__contentInner:last-child {
      margin-bottom: 0;
}

/* The link which when clicked opens the collapsable area */
.faq_wrapper .Collapsible__trigger {
  display: block;
  font-family: 'Besty';
  font-weight: 400;
  font-size: 1.2em;
  text-decoration: none;
  position: relative;
  color: white;
  text-align: center;
  border-radius: 10px;
  /* box-shadow: 5px 5px 0px #1F1F1F; */
  padding: 10px;
  background: var(--blue);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  transition: all ease-in-out 300ms;

}

.faq_wrapper .Collapsible__trigger:after {
    content: '↑';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
}

.faq_wrapper .Collapsible__trigger.is-open:after {
      transform: rotateZ(180deg);
      /* content: 'Less'; */
    }

.faq_wrapper .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
}

.faq_wrapper .CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
  
  
}

.faq_wrapper .CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.faq_wrapper .Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black;
}

/* MINT */
.mintMainContainer{
    overflow-x: hidden;
    background-color: rgb(50, 50, 241);
    
}
.navCointainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
}

a:link{
 text-decoration: none ;
}


.logo{
  height: 150px;
  width: auto;
  margin: -20px 20px;
}

.video_wrapper{
  margin-top: -60px;
  display: flex;
  width: 100%;
  justify-content: center;
  
}

.inn_video_wrapper{
  display: flex;
  width: 100%;
  max-width: 1000px;
  justify-content: center;
  position: relative;
}


.mint_soundlink {
  background-color: rgba(242, 103, 224, 0.8);
  border-radius: 99%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute ;
  top: 60px;
  right: 40px ;
  z-index: 2;
  cursor: pointer;
}

.soundlink_button {
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

}

.soundlink_button:hover{
  cursor: pointer;
}
.soundlink_button img{
  width: 50px; 
  height: 50px;
  cursor: pointer;
}
.VideoTag{
  width: 100%;
  max-width: 1000px;
  padding: 50px;
  
  
}

.MintContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto Condensed', sans-serif;
}

.mint_date{
  display: inline-block;
  width: auto;
  padding: 18px;
  border: 1px;
  border-radius: 0.5rem;
  background-color: rgb(243, 141, 158);
  font-weight: 600;
  
}

.inn_mint_wrapper{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.gif{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 4px dotted black;
}

.mintSection{
  margin-top: 20px;
  margin-left: 100px;
  margin-right: 100px;
  display: inline-block;
  padding: 20px  50px;
  border: 4px solid purple;
  border-radius: 0.5rem;
  background-color: pink;
  text-align: center;
}

.buttonContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.mintState {
 color: purple ;
 margin-top: 15px;
}

.totalMinted{
 margin-top: 30px;
}

.incButtonContainer{
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SVG{
  height: 40px;
  width: 40px;
  cursor: pointer;
  
}

.mintAmount{
  margin: 0px 25px;
  font-weight: bolder;
  color: purple;
}

.costDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-top: 30px;
  margin-bottom: 30px;
}

.costDiv h4 span {
  color: purple ;
  padding: 10px 10px;
}

.maxMint{
margin-top: 30px;
}

.mintButton{
  background: blue;
  margin-top: 36px;
  padding: 8px 36px;
  border: 1px;
  border-radius: 0.5rem;
  font-size: 18px;
  color: white;
  font-weight: 600;
  letter-spacing: 0.05rem;
}


.mintButton:hover{
  background: white;
  cursor: pointer;
  color: black;
}

.statusSuc {
margin-top: 36px;
padding: 0px 10px;
border-radius: 0.5rem;
border: 1px solid green;
}

.statusFail{
margin-top: 10px;
padding: 0px 36px;
border-radius: 0.5rem;
border: 1px solid red;
}

h4{
    font-size: 30px;
    color: white;
}

h5{
  font-size: 30px;
  font-weight: 600;

}


h5 span {
  font-size: 40px;
  font-weight: 600;

}

h6{
  font-size: 25px;
  font-weight: 500;
  

}


/* Collapsible */
.switch{
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 10px;
}

.switch input{
  opacity: 0;
  width: 0;
  height: 0;
}

.slider{
  position: absolute;
  cursor: pointer;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 2px;

  background-color: gray;
  transition: 400ms;
}

.slider:before{
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 400ms;
}

input:checked + .slider{
  background-color: #2196f3;
}

input:checked + .slider:before{
  transform: translateX(26px);
}

.slider.rounded{
   border-radius: 34px;
}

.slider.rounded:before{
  border-radius: 50%;
}

/* ROADMAP */
.roadmap{
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: auto;
  padding: 80px 0 120px 0;
  margin-top: -15vw;
  overflow: hidden;

  background-image: url('./img/roadmap-bg.png');
  background-position: bottom;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: var(--light-pink);
}

.visual_rm_container{
  display: flex;
  position: relative;
  flex-direction: row;
  margin-top: 30px;
  width: 90%;
  height: max-content;
  justify-content: space-evenly;
}

.rm_column2{
  display: flex;
  flex-direction: column;
  position: relative;
}


.rm_phase {
  width: 30vw;
  height: fit-content;
  padding: 10px;
  /* border: 1px solid #FFD439; */
  border-radius: 10px;
  position: relative;
}
.rm_phase_line{
  height: 4px;
  width: 26px;
  background: var(--yellow);
  position: relative;
  top: 40px;
  left: -15px;
}

.rm_phase_line::before{
  width: 14px;
  height: 14px;
  content: '';
  position: absolute;
  border-radius: 50%;
  background: var(--yellow);
  top: -5px;
  left: -40%;
}

.rm_phase1 h2{
  text-align: left;
}
.rm_phase p{
  font-weight: 400;
  line-height: 1.9;
  font-size: 1.2em;
  text-align: justify;
}

/* Roadmap images */
.rm_img1, .rm_img2{
  width: 160px;
  height: auto;
  position: absolute;
}
.rm_img3, .rm_img4{
  width: 120px;
  height: auto;
  position: absolute;
}
.rm_img1{
  right: 130%;
  top: -170px;
}
.rm_img2{
  left: 130%;
}
.rm_img3{
  right: 130%;
  top: 280px;
}
.rm_img4{
  left: 130%;
  top: 550px;
}

.rm_bg_img{
  position: absolute;
  height: 100vh;
  top: -90px;
  left: -60px;
}

.rm_phase_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}
.rm_phase_logo{
  width: 120px;
  margin-right: 20px;
}

.roadmap p {
  margin: 10px 0;
  font-size: 1em;
  line-height: 20px;
}

/* Roadmap Collapsibility */
.roadmap .Collapsible {
  background-color: #242527;
}

/* The content within the collaspable area */
.roadmap .Collapsible__contentInner {
  padding: 10px 20px;
  font-size: 1.2em;
  font-weight: 400;
  border: 1px solid var(--yellow);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 0;
}

.roadmap .Collapsible__contentInner:last-child {
      margin-bottom: 0;
}

/* The link which when clicked opens the collapsable area */
.roadmap .Collapsible__trigger {
  display: block;
  font-weight: 800;
  font-size: 1.8em;
  text-align: center;
  text-decoration: none;
  position: relative;
  border: 5px solid var(--yellow);
  border-radius: 10px;
  padding: 10px;
  background: #2c2c2c;
  color: var(--yellow);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.roadmap .Collapsible__trigger:after {
    content: '↓';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
}

.roadmap .Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
  /* content: 'Less'; */
}

.roadmap .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
}

.roadmap .CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.roadmap .CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.roadmap .Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black;
}

/* Partners */
.partners{
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 90%;
  margin: 10px 0;
}
.partners h2{
  font-size: 2.5em;
  margin-bottom: 20px;
}
.partners_container{
  width: 100%;

  padding: 40px;
  border-radius: 20px;

  background-color: rgb(43, 43, 43);
}
.row1{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 60px;
  padding: 0 60px;
}
.row2{
  display: flex;
  justify-content: space-evenly;
}
.partner_item{
  display: flex;
  justify-content: center;
  width: 80px;
  height: 80px;
}

.after_rm_text{
  width: 60%;
  margin-top: 100px;
}

.after_rm_text p{
  font-size: 1.2em;
  font-weight: 400;
}

/*  */

@media only screen and (max-width: 1250px){
  .member_item{
    height: 400px;
  }

  /* partners */
  .partners{
    width: 100%;
  }
.row1, .row2{
  padding: 0 10px;
  justify-content: space-between;
}
.partner_item{
  width: 60px;
  height: 60px;
}

}

/* Mobile Roadmap */
@media only screen and (max-width: 1160px){
  .about_container{
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .visual_rm_container{
    flex-direction: column;
    align-items: center;
  }

  .middle_element_rm{
    margin: 0;
  }

  .rm_phase1, .rm_phase2, .rm_phase3{
    width: 60vw;
    margin: 10px 10px;
  }

  .after_rm_text{
    width: 90%;
  }
/*   
  .planet, .wood_sign{
    display: none;
  } */

}

/* Team */
.team_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px 20px 20px;
  background: var(--light-pink);;
}
.team_container h2{
  font-size: 2em;
  font-family: 'besty';
  margin: 50px 0;
  color: var(--pink);
  text-shadow: 0px 3px 0px #1F1F1F;
}

.team_members{
  display: grid;
  gap: 4vw;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  width: 90%;
}

.member_item{
  width: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  text-align: left;
  border-radius: 8px;
}

.member_img{
  display: flex;
  width: fit-content;

  margin-bottom: 10px;

  justify-content: center;

  border-radius: 10px;
}
.member_img img{
  border-radius: 10px;
  box-shadow: 8px 8px 0px #1D1D1D;
}

.member_container{
  display: flex;
  align-items: center;
}
.member_container a{
  display: flex;
  justify-content: center;
  align-items: center;
}

.member_item span{
  font-size: 1.2em;
}
.member_item img{
  width: 80%;
  fill: #1d9bf0;
}

.member_item h3{
  font-family: 'Besty';
  font-size: 1.5em;
  margin: 5px 10px 5px 0;
}

.team_desc{
  font-weight: 400;
  font-size: 0.8;
  margin-top: 6px;
  width: 82%;
}

.team_role{
  text-align: center;
}
.team_links{
  display: flex;
}

/* Team Collapsible */
.team_container .Collapsible {
  background-color: #242527;
}


/* The content within the collaspable area */
.team_container .Collapsible__contentInner {
  padding: 10px;
  font-weight: 400;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 0;
}


.team_container .Collapsible__contentInner:last-child {
      margin-bottom: 0;
}

/* The link which when clicked opens the collapsable area */
.team_container .Collapsible__trigger {
  display: block;
  font-weight: 600;
  font-size: 1.2em;
  text-decoration: none;
  position: relative;
  padding: 10px;
  background: #2c2c2c;
  color: var(--yellow);
}

.team_container .Collapsible__trigger:after {
    content: '+';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
}

.team_container .Collapsible__trigger.is-open:after {
      /* transform: rotateZ(180deg); */
      content: '-';
    }

.team_container .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
}

.team_container .CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;  
}

.team_container .CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.team_container .Collapsible__custom-sibling {
  padding: 5px;
  font-size: 1.2em;
  background-color: #CBB700;
  color: black;
}


/* Popup */
.popup_pre_wrapper{
  margin:auto;
  position:absolute;
  top:270px;
  z-index:3;
  transform:scale(2);
}

.popup_wrapper{
  position: relative;
  width: 330px;
  padding: 10px;

  margin: auto;

  background-color: var(--pink);
  border-radius: 10px;
  text-align: center;
}
.popup_button{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  cursor: pointer;

  background-color: var(--light-pink);
  font-family: 'Kiddos';
  font-size: 1em;
  border: none;
  border-radius: 3px;
}

/* Footer */
.footer_wrapper{
  background-color: var(--pink);
  padding: 50px 0 20px 0;
  position: relative;

}
.footer{
  display: flex;
  justify-content:space-between;
  height: auto;
  align-items: center;

  text-align: center;
  position: relative;
}
.footer_left{
  /* width: 50%; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 100px;
  margin-top: 40px;
}
.footer_text{
  text-align: left;
}
.footer_left h3{
  color: white;
  font-size: 2em;
  text-shadow: 0px 3px 0px #2e2e2e;
  margin: 40px 0;
}
.footer_left p{
  color: white;
}
.footer_img{
  position: absolute;
  top: -90px;
  margin-left: 10%;
}
.footer_img img{
  width: 180px;
}
.footer_socials{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_socials a{
  margin-right: 2vw;
}
.footer_socials img{
  width: 27px;
}
.footer_menu{
  margin: 0 7vw;
  text-align: left;
}
.footer_links{
  /* width: 75%; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  position: relative;
}
.footer_menu li{
  margin-bottom: 30px;
  font-family: "Kiddos";
  font-size: 1.2em;
}

.footer a{
  text-decoration: none;
  color: white;
}
.footer_copyright{
  text-align: center;
  font-size: 1em;
  color: white;
}


@media only screen and (max-width: 1150px){
  .about_wrapper, .about_wrapperB{
    margin-top: -12%;
  }
}
@media only screen and (max-width: 980px){
  .about_wrapper, .about_wrapperB{
    margin-top: -17%;
  }

  .about_text2{
  background: none;
  }

  .roadmap{
    background-image: url('./img/roadmap_bg_mobile.png');
    background-position: bottom;
    background-size: cover;

    padding-bottom: 90px;
  }
  
  .pinkbox_wrapper{
    width: 75%;
    margin-bottom: 60px;
  }
  .pinkbox_wrapper:nth-child(6){
    width: 75%;
    margin-bottom: 220px;
  }

  .popup_pre_wrapper{
    top: 190px;
    transform:scale(1.4); 
  }
}
@media only screen and (max-width: 840px){
  .home_first_row_container{
    flex-direction: column;
  }
  .first_row_right, .first_row_left{
    width: auto;
    text-align: center;
  }

  .about_main_img{
    width: 160%;
  }
  .mint_wrapper{
    width: 100%;

  }
  .mint_r, .mint_l{
    width: 100%;
  }
  .mint_date{ 
    top: 130px;
  }
  .mint_box{
    width: 100%;
    border-radius: 0;
  }
}
@media only screen and (max-width: 750px){
  h2{
    font-size: 2.5em;
  }
  .home_first_row{
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .first_row_left{
    width: 90%;
    font-size: 1em;
  }
  .first_row_left p{
    font-size: 1em;
  }
  .about_wrapper, .about_wrapperB{
    width: 100%;
    margin-top: -10%;
  }

  .about_text ul{
    width: 80%;
  }

  .about_text h2{
    margin-top: 50px;
  }
  .about_container, .about_container2{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
  }
  .about_container2{
    height: auto;

    /* background-image: none;
    background-position:center;
    background-size: 100%;
    background-color: var(--blue); */
  }
  .about_img{
    width: 80%;
    display: flex;
    justify-content: center;
  }
  .about_img img{
    width: 60%;
    align-self: center;
  }
  .bottom_box p{
    font-size: 120%;
  }
  .about_text{
    width: auto;
    margin-top: 0;
  }
  
  .about_bottom_p{
    margin: 25px 0 150px 0;
    padding: 10px;
    font-size: 1.2em;
  }
  .hero2_container{
    padding: 40px 0;
  }
  .hero2_inner{
    width: 90%;
  }

  /*  */
  .vision_container{
    flex-direction: column;
  }
  .vision_element{
    width: 55%;
  }
  .bottom_box{
    padding: 15px;
    font-size: 1em;
    height: 170px;
    justify-content: center;
  }

  .header_p{
    width: 33%;
    font-size: 0.7em;
  }

  /* Footer */
  .footer{
    flex-direction: column;
    align-items: center;
  }
  .footer_left{
    align-items: center;
    justify-content: center;
    width: auto;

    margin-bottom: 30px;
    padding-left: 0;
  }
  .footer_text{
    text-align: center;
  }
  .footer_img{
    left: 17%;
  }
  .footer_copyright{
    position: static;

    width: 100%;
    font-size: 0.8em;
    text-align: center;

  }

  .team_container .Collapsible{
    width: 80%;
  }
  .roadmap{
    width: 100%;
  }
}



/* NFT HOLDINGS */
.stake_main, .stake_main_wrapper, .staking_wrapper, .staking_container{
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.stake_main_wrapper, .staking_container{
  margin-top: 30px;
}

.nft_holdings_container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 5vw;

  justify-content: center;
  align-items: center;
}

.nft_holdings{
  height: 120px;
  width: 120px;
}

.nft_item{
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

}

@media only screen and (max-width: 605px){
  .vision_element{
    width: 90%;
  }
  .bottom_box{
    width: 90%;
  }
  .blue_div_bg_cover{
    height: 750px;
  }

  .about_container2{
    /* height: 1300px;
    background-image: url('./img/planet_mobile.png');
    background-position: bottom;
    background-color: transparent; */
  }
}

@media only screen and (max-width: 584px){
  .member_item{
    height: auto;
  }
  .popup_pre_wrapper{
    transform:scale(1); 
  }
}
@media only screen and (max-width: 410px){
  .hero_main_img{
    display: none;
  }
  .hero_main_img2{
    display:block;
  }
  .home_first_row{
    margin-top: -15%;
  }
  .bottom_box{
    width: auto;
    height: max-content;
  }
  .bottom_box p{
    margin-top: 60px;
  }
  .blue_div_bg_cover{
    height: 1050px;
  }
}

.numbers{
  font-family: "Balsamiq Sans";
  color: white;
}
.numbers_black{
  font-family: "Balsamiq Sans";
  color: black;
  font-size: .8em;
}
